import type { Content } from "@prismicio/client";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import type * as prismicT from "@prismicio/types";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";

import { Order } from "@/types/models/Order";
import { chalk, log } from "@/utils/log";

import TestCardAction from "./TestCardAction";

type Props = {
  ordersData: Partial<Order>[] | null;
  test: prismicT.FilledContentRelationshipField<
    string,
    string,
    Content.TestTypeDocumentData
  >;
};

const IsLinkWrapper = ({ children, isLive, test, ...props }) =>
  isLive ? (
    <PrismicLink field={test} {...props}>
      {children}
    </PrismicLink>
  ) : (
    <div {...props}>{children}</div>
  );

const DynamicTestCardAction = dynamic(() => import("./TestCardAction"), {
  ssr: false,
});
const TestCard = ({ test, ordersData }: Props) => {
  const { data } = test;
  const description = data.description;
  const isLive = data.is_live;
  const featuredImage = data.featured_image.url;
  const title = data.test_name[0].text;
  const testUid = test.uid;

  //@ts-ignore - this is a bug in the prismic types
  const longTitle = data.test_long_name[0].text;
  return (
    <div className="col-span1">
      <div className="bg-white  pb-0 rounded-2xl shadow-md overflow-hidden">
        <IsLinkWrapper
          isLive={isLive}
          test={test}
          className="block px-6 py-8 pb-0"
        >
          <h4 className="text-3xl font-bold text-center lg:text-4xl leading-tight">
            {title}
          </h4>
          <div className="text-center mb-3 lg:mb-5 ">{longTitle}</div>
          <hr className="my-3 lg:my-5 border-theme h-2" />
          <div className="prose prose-md prose-lg text-slate-700 leading-tight mb-3 lg:mb-5">
            <PrismicRichText field={description} />
          </div>
          <div className="relative aspect-[4/3] overflow-hidden -mx-6">
            <Image
              className={`h-full w-full object-cover  
                          `}
              src={featuredImage}
              alt={longTitle}
              width={1000}
              height={1000}
            />
            <div className="absolute top-0 z-10 w-full h-full bg-gradient-to-b from-stone-100 via-stone-100/80 to-transparent"></div>
          </div>
        </IsLinkWrapper>
        {isLive ? (
          <TestCardAction
            orderData={Boolean(
              ordersData?.filter(
                (order) => order && order.test_uid === testUid
              )[0] ?? false
            )}
            test={test}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TestCard;
